import React, { useState } from "react";
import { BarChartSharp, Close, Menu } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar = () => {
  const routes = ["home", "about", "portfolio", "products"];
  const [visible, setVisible] = useState(false);

  return (
    <Stack
      sx={{
        bgcolor: "#ddd",
        width: "100%",
        padding: { md: "1rem 3rem", xs: ".9rem 1rem" },
        alignItems: "center",
        // boxShadow: "0px 0px 5px rgba(0,0,0,0.4)",
        justifyContent: "space-between",
        position: "sticky",
        top: "0px",

        zIndex: 30,
      }}
      direction={"row"}
    >
      <p style={{ fontSize: "1rem", fontFamily: "cursive" }}>Opeara Ocean</p>
      <Stack
        sx={{ gap: "1rem", display: { md: "flex", xs: "none" } }}
        direction={"row"}
      >
        {routes?.map((item, i) => (
          <Link
            style={{ color: "black" }}
            to={item == "home" ? "/" : item}
            key={i}
          >
            <Typography fontSize={"large"} textTransform={"capitalize"}>
              {item}
            </Typography>
          </Link>
        ))}
      </Stack>
      <Stack sx={{ display: { xs: "block", md: "none" } }}>
        <Menu onClick={() => setVisible(true)} />
        {visible && (
          <Stack
            sx={{
              position: "fixed",
              inset: "0px",
              bgcolor: "white",
              height: "100vh",
              zIndex: 50,
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>logo</div>
              <Close onClick={() => setVisible(false)} />
            </Box>
            <Stack spacing={4} sx={{ marginTop: "2rem" }}>
              <Divider />
              {routes.map((item, i) => (
                <Link
                  onClick={() => setVisible(false)}
                  to={item == "home" ? "" : item}
                  style={{ color: "black" }}
                >
                  <Typography>{item}</Typography>
                  <Divider />
                </Link>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Navbar;
