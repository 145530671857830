import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

const MissionCardReveal = ({ revealText }) => {
  return (
    <Grid
      item
      md={6}
      xs={12}
      sx={{
        borderTopLeftRadius: "1.5rem",
        borderBottomLeftRadius: "1.5rem",
        padding: "1rem 2rem",
        minHeight: "60vh",
        order:{md:2 , xs:1}
      }}
    >
      <Stack
        sx={{
          width: {
            md: "80%",
            xs: "90%",
            minHeight: "100%",
            paddingTop: "2rem",
            justifyContent: "space-between",
          },
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Typography textAlign={"left"} sx={{ fontSize: "1.3rem" }}>
            {revealText}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default MissionCardReveal;
