import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <Box
      className="hero_img"
      sx={{
        height: "100dvh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: { md: "0 4rem", xs: "0 1rem" },
      }}
    >
      <motion.div
        transition={{
          delayChildren: 1,
          type: "tween",
          ease: "easeIn",
        }}
        style={{ width: "100%" }}
      >
        <Stack spacing={2} sx={{ width: { md: "50%", xs: "100%" } }}>
          <motion.div
            whileInView={{
              y: [-100, 0],
            }}
            transition={{
              duration: ".5",
            }}
          >
            <Typography
              sx={{ color: "white", fontSize: { md: "3rem", xs: "1.1rem" } }}
              textTransform={"capitalize"}
            >
              Disrupting Post-Ultra Modernism Technologies
            </Typography>
          </motion.div>
          <motion.div
            whileInView={{
              y: [-100, 0],
            }}
            transition={{
              duration: ".6",
            }}
          >
            <Typography color={"white"}>
              Adding value to Life.
            </Typography>
          </motion.div>
          <motion.div
            whileInView={{
              y: [-100, 0],
            }}
            transition={{
              duration: ".7",
            }}
          >
            <Box
              sx={{
                padding: "1rem 3rem",
                borderRadius: "2rem",
                bgcolor: "white",
                alignSelf: "start",
                fontSize: "1.4rem",
                width: "fit-content",
              }}
            >
              <Typography>See How</Typography>
            </Box>
          </motion.div>
        </Stack>
      </motion.div>
    </Box>
  );
};

export default Hero;
