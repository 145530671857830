import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

const CatalogItems = ({
  imgurl,
  title,
  ctaText,
  desc,
  leftOrder,
  rightOrder,
}) => {
  return (
    <Grid
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ marginBlock: "1rem", gap: "1rem" }}
      container
    >
      <Grid item order={leftOrder} xs={12} md={5}>
        <motion.div
          whileInView={{
            x: leftOrder == 2 ? [-50, 0] : [50, 0],
          }}
          transition={{ ease: "easeIn", type: "spring" }}
        >
          <Box sx={{ width: "100%" }}>
            <img width={"100%"} src={imgurl} alt="" />
          </Box>
        </motion.div>
      </Grid>

      <Grid item order={rightOrder} xs={12} md={5}>
        <motion.div
          whileInView={{
            x: rightOrder == 1 ? [-50, 0] : [50, 0],
          }}
          transition={{ ease: "easeIn", type: "spring" }}
        >
          <Stack sx={{ width: { md: "80%", xs: "100%" } }} spacing={3}>
            <Typography sx={{ fontSize: "2rem", fontWeight: "medium" }}>
              {title}
            </Typography>
            <Typography>{desc}</Typography>
            <Box
              sx={{
                bgcolor: "#008dff",
                padding: { md: "1rem 3rem", xs: "1rem 2rem" },
                borderRadius: "3rem",
                width: "fit-content",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { md: "1.1rem", xs: ".9rem" },
                }}
                color="white"
              >
                {ctaText}
              </Typography>
            </Box>
          </Stack>
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default CatalogItems;
