import React from "react";

import EcoTrackPrivacy from "../components/ecotrack_privacy"

const EcoTrack = () => {
  return (

     <EcoTrackPrivacy />

  );
};

export default EcoTrack;
