import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

const IntroducingCard = ({ imgUrl, title, subtitle, desc }) => {
  return (
    <Grid
      sx={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.3)" }}
      item
      xs={12}
      md={3.5}
    >
      <motion.div
        whileInView={{ y: [100, 0] }}
        transition={{ type: "spring", damping: 10,}}
      >
        <Stack spacing={3}>
          <img style={{ width: "100%" }} src={imgUrl} alt="item image" />

          <Stack sx={{ padding: "1.4rem" }} spacing={4}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
              {title}
            </Typography>
            <Typography sx={{ fontSize: "1.4rem" }}>{subtitle}</Typography>
            <Typography sx={{ fontSize: "1.1rem", lineHeight: "1.5rem" }}>
              {desc}
            </Typography>
          </Stack>
        </Stack>
      </motion.div>
    </Grid>
  );
};

export default IntroducingCard;
