import React from "react";
import { Box } from "@mui/material";

import Summary from "../components/Summary";
import Hero from "../components/Hero";
import Introducing from "../partials/Introducing";
import Catalog from "../partials/Catalog";

const Root = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100vw",
        // padding: { md: "0 3rem", xs: "0" },
      }}
    >
      <Hero />
      <Introducing />
      <Summary />
      <Catalog />
    </Box>
  );
};

export default Root;
