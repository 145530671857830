import React from "react";
import { Box, Container, Grid, Paper, Stack } from "@mui/material";
import { motion } from "framer-motion";

import MissionCard from "./MissionCard";

const Missions = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "80vh",
        padding: "2rem 0px",
        marginTop: "5rem",
      }}
    >
      <Stack sx={{ gap: "6rem" }}>
        <MissionCard
          text={"Our Business Conduct Policy"}
          desc={"Honesty, Respect, Confidentiality, and Compliance"}
          imgUrl={
            "https://images.macrumors.com/t/BCFCToGqT4ZpiARPeLZ094AiIoA=/800x0/article-new/2019/09/Apple-Store-fifth-avenue-new-york-redesign-interior-091919.jpg?lossy"
          }
          revealText={
            "     A number of compliance functions are deeply integrated into our business organization. Apple’s Business Conduct and Global Compliance team focuses on Business Conduct, Political Compliance, Export and Sanctions Compliance, Health Compliance, Antitrust Compliance, and Anti-Corruption Compliance."
          }
        />

        <MissionCard
          text={"Compliance at Apple"}
          imgUrl={
            "https://photos5.appleinsider.com/gallery/40498-78025-210224-Compliance-xl.jpg"
          }
          revealText={
            "Our Business Conduct Policy sets out our ethical requirements for our employees. Each of our employees must certify that they have read and understand the policy when they join Apple, and again each year. Violations of the policy are taken seriously and may result in disciplinary action, up to and including termination of employment."
          }
        />

        <MissionCard
          text={"Our Business Conduct Policy"}
          desc={"Honesty, Respect, Confidentiality, and Compliance"}
          imgUrl={
            "https://images.macrumors.com/t/BCFCToGqT4ZpiARPeLZ094AiIoA=/800x0/article-new/2019/09/Apple-Store-fifth-avenue-new-york-redesign-interior-091919.jpg?lossy"
          }
          revealText={
            "     A number of compliance functions are deeply integrated into our business organization. Apple’s Business Conduct and Global Compliance team focuses on Business Conduct, Political Compliance, Export and Sanctions Compliance, Health Compliance, Antitrust Compliance, and Anti-Corruption Compliance."
          }
        />

        <MissionCard
          text={"Compliance at Apple"}
          imgUrl={
            "https://photos5.appleinsider.com/gallery/40498-78025-210224-Compliance-xl.jpg"
          }
          revealText={
            "Our Business Conduct Policy sets out our ethical requirements for our employees. Each of our employees must certify that they have read and understand the policy when they join Apple, and again each year. Violations of the policy are taken seriously and may result in disciplinary action, up to and including termination of employment."
          }
        />
      </Stack>
    </Box>
  );
};

export default Missions;
