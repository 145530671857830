import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

import ProductsCard from "./ProductsCard";
import mac1 from "../assets/mac1.webp";
import mac2 from "../assets/mac2.avif";
import mac3 from "../assets/mac3.avif";
import IntroducingCard from "./IntroducingCard";

const Introducing = () => {
  return (
    <Stack
      sx={{ minHeight: "100vh", marginTop: "2rem" }}
      spacing={3}
      alignItems={"center"}
    >
      <Typography sx={{ fontSize: { md: "3rem", xs: "1re," } }}>
        Introducing Opera Ocean
      </Typography>
      <Grid
        container
        sx={{
          padding: "1rem 2rem",
          justifyContent: "space-around",
          gap: "1rem",
        }}
      >
        <IntroducingCard
          title={"User Protection Suite"}
          subtitle={"Smart simple Security for your end users"}
          desc={
            "Give teams secure access to any application on any device from anywhere. With zero trust—and zero friction. "
          }
          imgUrl={mac1}
        />{" "}
        <IntroducingCard
          title={"User Protection Suite"}
          subtitle={"Smart simple Security for your end users"}
          desc={
            "Give teams secure access to any application on any device from anywhere. With zero trust—and zero friction. "
          }
          imgUrl={mac2}
        />{" "}
        <IntroducingCard
          title={"User Protection Suite"}
          subtitle={"Smart simple Security for your end users"}
          desc={
            "Give teams secure access to any application on any device from anywhere. With zero trust—and zero friction.  "
          }
          imgUrl={mac3}
        />
      </Grid>
    </Stack>
  );
};

export default Introducing;
