import React from "react";
import { Container, Stack, Typography, Box } from "@mui/material";
import { Splide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import ServiceCard from "../partials/ServiceCard";
import { summary, summary1, summary2, summary3 } from "../assets";

const Summary = () => {
  const options = {
    type: "carousel",
    loop: true,
    perPage: 3,
    rewind: true,
    perMove: 1,
    autoplay: true,
    arrows: true,
    pagination: false,
    gap: "2rem",
    breakpoints: {
      640: {
        perPage: 2,
        gap: "1rem",
      },
    },
  };
  return (
    <Box sx={{ marginTop: "2rem", padding: "0 3rem" }}>
      <Stack alignItems={"center"} gap={"2rem"}>
        <Typography fontSize={"2rem"} fontWeight={"bolder"}>
          Services
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: { md: "10%", xs: "15$" },
          }}
        >
          <Splide options={options}>
            <ServiceCard
              imgUrl={summary}
              description={"Web Design and Development"}
            />
            <ServiceCard
              imgUrl={summary1}
              description={"Mobile and Desktop Application Design and Development"}
            />
            <ServiceCard
              imgUrl={summary3}
              description={"Data Analytics"}
            />
            <ServiceCard
              imgUrl={summary2}
              description={"Cloud Computing"}
            />
            <ServiceCard
              imgUrl={summary3}
              description={"Project Management"}
            />
          </Splide>
        </Box>
      </Stack>
    </Box>
  );
};

export default Summary;
