import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

import PortfolioRedirectText from "../partials/PortfolioRedirectText";

const PortfolioCards = ({ imgUrl, Title, Brief }) => {
  return (
    <Grid sx={{ marginTop: "1rem" }} item xs={12} md={5.8}>
      <motion.div
        whileInView={{
          scale: [0, 1],
        }}
        transition={{
          type: "tween",
        }}
      >
        <Stack spacing={2}>
          <img
            style={{ borderRadius: "10px" }}
            width={"100%"}
            src={imgUrl}
            alt="image"
          />
          <PortfolioRedirectText title={Title} brief={Brief} />
        </Stack>
      </motion.div>
    </Grid>
  );
};

export default PortfolioCards;
