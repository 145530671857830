import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

import PortfolioCards from "../components/PortfolioCards";
import img1 from "../assets/fpcx_banner_1920x1080-2.jpg.webp";
import img2 from "../assets/tree.webp";
import img3 from "../assets/solar.webp";
import img4 from "../assets/Ai.webp";
import PortfolioasProposals from "../components/portfolioAsProposals";

const PortfolioPage = () => {
  return (
    <Stack spacing={6}>
      <Stack
        sx={{
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: { md: "4rem", xs: "1rem" } }}>
          Some Brief Description
        </Typography>
      </Stack>
      <Grid sx={{ padding: "1rem", justifyContent: "space-between" }} container>
        <PortfolioCards
          imgUrl={img1}
          Title={"Bulls Road Funding"}
          Brief={"a Reliable UK Based Proprietary Firm, that funds forex Traers all over the World"}
        />{" "}
        <PortfolioCards
          imgUrl={img2}
          Title={"Blue Loop App"}
          Brief={
            "A lightweight Crypto trading application with exciting features"
          }
        />{" "}
        <PortfolioCards
          imgUrl={img4}
          Title={"Bounce Back"}
          Brief={"A hospital management system built for Clinics and Hospitals"}
        />{" "}
        <PortfolioCards
          imgUrl={img3}
          Title={"Github.ng"}
          Brief={"Open source task and workflow automation software available to everyone"}
        />
      </Grid>
      <PortfolioasProposals />
    </Stack>
  );
};

export default PortfolioPage;
