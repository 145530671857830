import React from "react";

import ProductsCard from "../partials/ProductsCard";
import { Grid } from "@mui/material";
import { Cars, phones, Tablets, Watches, products_head_Image } from "../assets";
import ProductsHead from "../partials/ProductsHead";

const Products = () => {
  return (
    <>
    <ProductsHead />
      <Grid
        sx={{
          gap: "1.2rem",
          marginTop: "2rem",
          justifyContent: "space-around",
        }}
        container
      >
        <ProductsCard
          imgUrl={phones}
          title={"Healthometer"}
          desc={
            " Healthometer delivers health services to users. Ranging from consultation to analytics, softwares and management systems."
          }
        />
        <ProductsCard
          imgUrl={Tablets}
          title={"Our Market"}
          desc={
            " Our Marketing App gives sellers, retailers, wholesalers and manufacturers an AI powered ultra modern platform to reach business goals."
          }
        />
        <ProductsCard
          imgUrl={Watches}
          title={"Shire Fin"}
          desc={
            "Shire fin, the short form for Shire Finance, makes finance easy for all. Integrating ultra modern technologies to make transactions easy for all."
          }
        />
        <ProductsCard
          imgUrl={Cars}
          title={"Tourmaline"}
          desc={
            "Tourmaline gives travellers the perfect view and insights on their journey, so they can maximize their travelling experience."
          }
        />
      </Grid>
    </>
  );
};

export default Products;
