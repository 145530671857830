import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

import "../styles/about.css";

const AboutHero = () => {
  return (

      <Container
        sx={{
          display: "flex",
          minHeight: "70vh",
          justifyContent: "center",
          alignItem: "center",
        }}
      >
        <Stack
          spacing={3}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Typography
            textAlign={"center"}
            fontWeight={"bold"}
            sx={{ fontSize: { xs: "1.3rem", md: "4rem" } }}
          >
            Ethics and Compliance
          </Typography>
          <Typography
            textAlign={"justify"}
            sx={{
              width: { md: "80%", xs: "100%" },
              lineHeight: { md: "2rem", xs: "1.3rem" },
              fontSize: { md: "1.3rem", xs: "1rem" },
            }}
          >
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minus hic
            esse praesentium omnis vel saepe rerum quaerat atque, sapiente
            eveniet libero facilis aliquid, sit, cupiditate voluptatum optio nam
            reprehenderit officia officiis. Deleniti, sapiente! Mollitia quam
            dolores laudantium id ipsa voluptates porro tempora laboriosam quas
            illo quibusdam ut aspernatur, rem ipsam?
          </Typography>
          <Typography
            textAlign={"center"}
            fontWeight={"medium"}
            fontStyle={"italic"}
            sx={{
              fontSize: { md: "2rem", xs: "1.1rem" },
              width: { md: "80%", xs: "100%" },
            }}
          >
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus
            neque, possimus ea deserunt blanditiis cum?"
          </Typography>
        </Stack>
      </Container>
  );
};

export default AboutHero;
