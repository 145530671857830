import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import birdnokia from "../assets/birdnokia.webp";
import path from "../assets/pathfinder.webp";
import reaclConvo from "../assets/realConvo.webp";
import PortfolioCards from "./PortfolioCards";
import PortfolioRedirectText from "../partials/PortfolioRedirectText";
import PortfolioSplide from "../partials/PortfolioSplide";

const PortfolioasProposals = () => {
  const options = {
    type: "carousel",
    loop: true,
    perPage: 3,
    rewind: true,
    perMove: 1,
    autoplay: true,
    arrows: true,
    pagination: false,
    gap: "2rem",
    breakpoints: {
      740: {
        perPage: 2,
        gap: "1rem",
      },
    },
  };

  return (
    <Stack
      sx={{
        minHeight: { md: "110vh", xs: "80vh" },
        width: "100%",
        marginBottom: "4rem",
      }}
    >
      <Box
        sx={{
          bgcolor: "#3788d8",
          height: { md: "50dvh", xs: "30dvh" },
          display: "flex",
          alignItems: "center",
          padding: { md: "0 2rem", xs: "0 1rem" },
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "3rem ", xs: "1.5rem" },
            width: { md: "70%", xs: "100%" },
            color: "white",
          }}
        >
          Discover the exponential potential of networks
        </Typography>{" "}
        <Box
          className="portfolio-splide"
          sx={{
            //   bgcolor: "red",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: { xl: "-90%", xs: "-85%", md: "-70%" },
            width: "90%",
          }}
        >
          <Splide options={options}>
            <PortfolioSplide
              imgurl={birdnokia}
              title={"Creative Economy"}
              brief={"The hidden value in your waste"}
            />{" "}
            <PortfolioSplide
              imgurl={reaclConvo}
              title={"Creative Economy"}
              brief={"The hidden value in your waste"}
            />{" "}
            <PortfolioSplide
              imgurl={path}
              title={"Creative Economy"}
              brief={"The hidden value in your waste"}
            />{" "}
            <PortfolioSplide
              imgurl={birdnokia}
              title={"Creative Economy"}
              brief={"The hidden value in your waste"}
            />{" "}
            <PortfolioSplide
              imgurl={birdnokia}
              title={"Creative Economy"}
              brief={"The hidden value in your waste"}
            />
          </Splide>
        </Box>
      </Box>
    </Stack>
  );
};

export default PortfolioasProposals;
