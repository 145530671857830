import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import {motion } from 'framer-motion'

import MissionCardText from "../partials/MissionCardText";
import MissionCardReveal from "../partials/MissionCardReveal";
import MissionCardImage from "../partials/MissionCardImage";

const MissionCard = ({ text, imgUrl, desc , revealText }) => {
  const [morevisible, setMorevisible] = useState(false);
  return (
   <motion.div
    whileInView={{scale:[0.3 , 1]}}
    transition={{ type:'spring ' , damping:10 , stiffness:100 , duration:1}}

   >
     <Container
      sx={{
        bgcolor: "#e8e8ed",
        width: { md: "80%", xs: "90%" },
        minHeight: "60vh",
        borderRadius: "1.5rem",
      }}
      style={{ padding: "0px" }}
    >
      <Grid sx={{ flexDirection: { md: "row", xs: "column" },  }} container>
        <MissionCardText  toggle={() => setMorevisible(prev => !prev)} text={text} desc={desc}  visible={morevisible} />
        {morevisible ? <MissionCardReveal revealText={revealText} /> : <MissionCardImage imgUrl={imgUrl} />}
      </Grid>
    </Container>
   </motion.div>
  );
};

export default MissionCard;
