import React from "react";
import { Stack } from "@mui/material";

import cat1 from "../assets/cat1.webp";
import cat2 from "../assets/cat2.webp";
import cat3 from "../assets/cat3.webp";
import CatalogItems from "./CatalogItems";

const Catalog = () => {
  return (
    <Stack
      spacing={7}
      sx={{ minHeight: "100vh", padding: "0 3rem", marginTop: "6rem" }}
    >
      <CatalogItems
        title={"Our Marketing Software"}
        desc={
          "Our Marketing App gives sellers, retailers, wholesalers and manufacturers an AI powered ultra modern platform to reach business goals. "
        }
        ctaText={"Start selling, start shopping"}
        imgurl={cat1}
      />
      <CatalogItems
        title={"Our Health Software"}
        desc={
          " Healthometer delivers health services to users. Ranging from consultation to analytics, softwares and management systems.olutions. Optimize your space and create connected experiences. "
        }
        ctaText={"Get Support, get good health"}
        imgurl={cat2}
        leftOrder={2}
        rightOrder={1}
      />
    </Stack>
  );
};

export default Catalog;
