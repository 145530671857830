import React from "react";
import { Grid } from "@mui/material";

import '../styles/about.css'

const MissionCardImage = ({imgUrl}) => {
  return (
    <Grid
      item
    xs={12}
    md={6}
      sx={{
        height: "100%",

        borderTopRightRadius: "1.5rem",
        borderBottomRightRadius: "1.5rem",
        minHeight: "60vh",
        position:'relative',
        order:{md:'2' , xs:'1'}
      }}
    >
      <img
        src={imgUrl}
        className="about__missions_img"
      />
    </Grid>
  );
};

export default MissionCardImage;
