import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PortfolioRedirectText = ({ title, brief }) => {
  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Typography sx={{ fontSize: { md: "1.6rem", xs: "1rem" } }}>
          {title}
        </Typography>
        <ArrowForwardIcon color="primary" fontSize="large" />
      </Box>
      <Typography>{brief}</Typography>
    </Stack>
  );
};

export default PortfolioRedirectText;
