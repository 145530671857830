import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import { Stack, Typography } from "@mui/material";

import "../styles/service.css";

const ServiceCard = ({ description, imgUrl }) => {
  return (
    <SplideSlide>
      <Stack gap={4}>
        <div>
          <img width={"90%"} className="service_image" src={imgUrl} alt="" />
        </div>
        <Typography
          sx={{ fontSize: { md: "1.4rem", xs: "1rem" }, fontWeight: "medium " }}
        >
          {description}
        </Typography>
      </Stack>
    </SplideSlide>
  );
};

export default ServiceCard;
