import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { products_head_Image } from "../assets";

const ProductsHead = () => {
  return (
    <Stack spacing={3} sx={{ alignItems: "center", marginBottom: "1rem" }}>
      <Box sx={{ width: { md: "50%", xs: "100%" } }}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={products_head_Image}
          alt=""
        />
      </Box>

      <Typography textAlign={'center'} sx={{ fontSize:{ md: "4rem ", xs: "2rem" } }}>
        Our Products are tailored directly to you(sers) individually {" "}
      </Typography>
      <Typography sx={{ width: {md:'65%' , xs:'100%'} ,fontSize:{ md: "2rem ", xs: "1.3rem" } }} textAlign={"center"} variant="h4">
        Now you can easily take control of you daily activities, finances and improve your well being and productivity
      </Typography>
    </Stack>
  );
};

export default ProductsHead;
