import React from "react";
import { Stack } from "@mui/material";
import { SplideSlide } from "@splidejs/react-splide";

import PortfolioRedirectText from "./PortfolioRedirectText";

const PortfolioSplide = ({ imgurl, title, brief }) => {
  return (
    <SplideSlide>
      <Stack>
        <img
          style={{ borderRadius: "10px", width: "90%" }}
          src={imgurl}
          alt="image"
        />
        <PortfolioRedirectText title={title} brief={brief} />
      </Stack>
    </SplideSlide>
  );
};

export default PortfolioSplide;
