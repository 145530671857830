import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const MissionCardText = ({ text, desc, toggle, visible }) => {
  return (
    <Grid
      item
      md={6}
      xs={12}
      sx={{
        borderTopLeftRadius: "1.5rem",
        borderBottomLeftRadius: "1.5rem",
        padding: "1rem 3rem",
        order:{md:1 , xs:2},

      }}
    >
      <Stack
        sx={{
          width: {
            md: "80%",
            xs: "90%",
            minHeight: "100%",
            paddingTop: "2rem",
            justifyContent: "space-between",
          },
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Typography
            sx={{ fontSize: { md: "3rem", xs: "1.7rem" }, fontWeight: "bold" }}
          >
            {text}
          </Typography>
          {desc && (
            <Typography
              fontWeight={"medium "}
              sx={{ fontSize: "1.3rem", fontWeight: "medium" }}
            >
              {desc}
            </Typography>
          )}
        </Stack>
        <Stack sx={{marginTop:{md:'0' , xs:'1rem'}}} alignItems={"center"} direction={"row"} spacing={2}>
          <AddCircleOutlineIcon

            onClick={() => toggle()}
            sx={{
              fontSize:'43px',
              fontWeight: "light",
              transform: `${!visible ? "" : " rotate(45deg)"}`,
              transition: "transform .3s ease-in",
            }}
          />

          {!visible && <Typography>More</Typography>}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default MissionCardText;
