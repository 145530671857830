import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import AboutHero from "../components/AboutHero";
import Missions from "../components/Missions";

const About = () => {
  return (
    <>
     <Box  className="about__bg" sx={{ minHeight:'100vh' , width:'100vw !important' }}>
      <Container
        sx={{
          padding: "1.5rem",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography fontSize={"1.4rem"} fontWeight={"medium"}>
          Ethic And Compliances
        </Typography>
        <Divider />
      </Container>
     <AboutHero />
    </Box>
    <Missions />
    </>
   
  );
};

export default About;
