import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

import { phones } from "../assets";

const ProductsCard = ({ imgUrl, title, desc }) => {
  return (
    <Grid
      xs={12}
      md={5.5}
      xl={2.5}
      sx={{
        borderRadius: "15px",
        border: "1px solid #dedede",
        minHeight: "550px",
        paddingLeft: "0px !important",
        paddingTop: "0px !important",
      }}
      item
    >
      {" "}
      <motion.div
        style={{ height: "100%", borderRadius: "15px" }}
        whileInView={{ y: [100, 0] }}
        whileHover={{
          border: "3px solid #1a73e8",
          boxShadow: "2px 2px 10px lightgray",
        }}
        transition={{ type: "spring", damping:10 }}
      >
        <Stack sx={{ cursor: "pointer" }} spacing={3}>
          <img
            style={{
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
            src={imgUrl}
            alt=""
          />
          <Stack
            sx={{
              marginTop: "1rem",
              alignItems: "center",
              padding: "0 1rem",
              justifyContent: "space-around",
            }}
          >
            <Typography
              letterSpacing={2}
              sx={{ fontWeight: "medium", fontSize: "1.4rem" }}
            >
              {title}
            </Typography>
            <Typography
              color={"#acacac"}
              textAlign={"center"}
              sx={{
                fontSize: { md: "1.3rem", xs: "1rem" },
                fontWeight: "medium",
              }}
            >
              {desc}
            </Typography>
            <Typography
              sx={{
                marginBlock: { md: "1.8rem", xs: "1rem" },
                fontSize: { md: "1.4rem", xs: "1rem" },
              }}
              color="#1a73e8"
            >
              Learn More
            </Typography>
          </Stack>
        </Stack>
      </motion.div>
    </Grid>
  );
};

export default ProductsCard;
