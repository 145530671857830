import { Box, Container, Stack } from "@mui/material";
import React from "react";
import Products from "../components/Products";

const ProductsPage = () => {
  return (
    <Stack
      sx={{
        padding: "1rem 2rem",
        alignItems: "center",
        width: "100vw",
      }}
    >

      <Products />
    </Stack>
  );
};

export default ProductsPage;
