import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

const Text = ({ text }) => {
  return (
    <Typography
      sx={{
        fontSize: {
          md: "1.1rem",
          xs: ".9rem",
        },
        borderRight: "1px solid gray",
        paddingRight: "8px",
      }}
      color="gray"
    >
      {text}
    </Typography>
  );
};

const Footer = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        minHeight: "10vh",
        bgcolor: "#ddda",
        marginTop: "10vh",
        padding: "2rem ",
      }}
    >
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        <Typography color={"gray"} sx={{ width: "60%" }}>
          Copyright © 2024 OPERA OCEAN Inc. All rights reserved.
        </Typography>
        <Stack
          // spacing={4}
          direction={"row"}
          sx={{
            flexWrap: "wrap",
            gap: "1rem",
            // justifyContent: "space-between ",
          }}
        >
          <Text text={"Privacy Policy"} />
          <Text text={"Terms Of Use"} />
          <Text text={"News"} />
        </Stack>
      </Box>
    </Stack>
  );
};

export default Footer;
